/* eslint-disable */

import React, { useState, useEffect, useRef } from "react";
import DATA from "../../data/Data";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { trigger } from "../../storage/redux/tags";
import { success } from "../../storage/redux/Filter/ducks";
// MATERIAL UI
import { Close } from "@material-ui/icons";
// BOOTSTRAP
import { Container, Row, Image } from "react-bootstrap";
// STYLED COMPONENTS
import { Button, Fixed, Left, Right, FilterText, MainTitle } from "./styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function FilterMenu(props) {
  // STATE HOOKS
  const img = null,
    title = "",
    isTitle = false;
  const [data, setData] = useState([]);
  const [currentTag, setCurrentTag] = useState(null);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const mobileScreens = useMediaQuery("(max-width: 750px)");

  // REDUX HOOKS
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.filter);
  const { tags } = useSelector((state) => state.tags);
  const { projects, project } = useSelector((state) => state.projects);

  // PROPS
  const myRef = useRef(<div />);
  var clientY = 0;

  const [animatedBorderContainerHeight, setAnimatedBorderContainerHeight] =
    useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (mobileScreens) setAnimatedBorderContainerHeight("calc(100% - 20vh)");
      else setAnimatedBorderContainerHeight("calc(100% - 92vh + 18px)");
    }, 500);
  }, [mobileScreens]);

  useEffect(() => {
    if (tags && tags.length > 0 && filter.currentFilter === 0) {
      setCurrentTag(tags[0].id);
      dispatch(
        success({ currentFilter: tags[0].id, currentFilterName: tags[0].name })
      );
    } else {
      setCurrentTag(filter.currentFilter);
    }
  }, [tags]);

  useEffect(() => {
    if (projects.length > 0) {
      dispatch(trigger());
    }
  }, [dispatch, projects]);

  useEffect(() => {
    for (let index = 0; index < projects.length; index++) {
      const element = projects[index];
    }
    if (currentTag) {
      const filterProjects = projects.filter((project) => {
        const idTags = project.tags.map((tag) => tag.id);
        if (idTags.includes(currentTag)) {
          return true;
        }
        return false;
      });
      setFilteredProjects(filterProjects);
    } else {
      setFilteredProjects(projects);
    }
  }, [currentTag, projects]);

  // WHEN THE PROJECTS LIST COMPONENT GETS RENDERED, THIS ACTIVATES A SCROLL BOTTOM, or at least this is what it should be doing...
  // OBS: THIS IS NOT WORKING?
  const executeScroll = () => {
    const projectButtonsContainer = document.getElementById(
      "project-buttons-container"
    );

    const test = myRef.current;

    if (mobileScreens) {
      /* projectButtonsContainer.scrollTo(0, test.offsetTop - 300); */
      projectButtonsContainer.scrollTo(0, test.offsetTop - 42);
    } else {
      projectButtonsContainer.scrollTo(0, test.offsetTop - 5);
      // projectButtonsContainer.scrollTo(0, test.offsetTop - 25);
    }
  };

  const closeFilterMenu = () => {
    document.body.style.overflow = "auto";
    setAnimatedBorderContainerHeight("0%");
    setTimeout(() => {
      props.close();
    }, 500);
  };

  useEffect(() => {
    handleSubmitAPI();

    document.body.classList.add("bg-black");
    document.body.classList.add("fadePage");

    //fade Page
    let el = document.querySelector(".fadePage");
    el.classList.add("fadeIn");
    if (isTouchDevice()) {
      window.addEventListener("touchmove", detectTouchMove, {
        passive: true,
      });
    } else {
      window.addEventListener("mousewheel", detectMouseWheelDirection);
    }

    return () => {
      document.body.classList.remove("bg-black");
      if (isTouchDevice) {
        window.removeEventListener("touchmove", detectTouchMove);
      } else {
        window.removeEventListener("mousewheel", detectMouseWheelDirection);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    executeScroll();
  }, [data]);

  useEffect(() => {
    if (!mobileScreens) {
      setTimeout(() => {
        document.getElementById("top-black-box").style.display = "block";
      }, 500);
    }
  }, []);

  const handleSubmitAPI = () => {
    setData(DATA);
  };

  const goto = (page, name) => {
    setTimeout(function () {
      closeFilterMenu();
      props.history.push("/work/" + page);
      props.changePage(name);
    }, 600);
  };

  const renderButtons = () => {
    filteredProjects.sort((a, b) =>
      a.position_id * 1 > b.position_id * 1
        ? 1
        : b.position_id * 1 > a.position_id * 1
        ? -1
        : 0
    );

    return filteredProjects.map((proj, index) => (
      <div
        className="filter-wrapper"
        key={proj.codename}
        ref={proj.codename === project.codename ? myRef : null}
      >
        <Button
          className="project-button"
          onClick={() => goto(proj.codename, proj.name)}
          isAtive={proj.codename === project.codename}
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflowX: "hidden",
          }}
        >
          {proj.name.length > 30 && window.innerWidth < 500 ? (
            <>
              <p className="big-nav-button-wrapper">{proj.name}</p>
            </>
          ) : (
            proj.name
          )}
        </Button>
      </div>
    ));
  };

  const detectMouseWheelDirection = (e) => {
    let delta = null;
    let direction = false;

    if (!e) {
      // if the event is not provided, we get it from the window object
      e = window.event;
    }

    // wheelDelta IS DEPRACATED

    if (e.wheelDelta) {
      // will work in most cases
      delta = e.wheelDelta / 60;
    } else if (e.detail) {
      // fallback for Firefox OBS: FALLBACK NOT WORKING, FEATURE NEVER ADDED BY FIREFOX.
      delta = -e.detail / 2;
    }

    let off = 0;

    if (document.getElementById("project-buttons-container")) {
      off = document.getElementById("project-buttons-container").scrollTop; // Prop scroll top of this node
    }

    // This is getting called whenever you scroll past a certain limit

    return direction;
  };

  const detectTouchMove = (e) => {
    clientY = e.changedTouches[0].clientY;
  };

  const isTouchDevice = () => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  };

  return (
    <Container
      fluid
      style={{
        display: props.active ? "block" : "none",
        overflowX: "hidden",
        position: "relative",
      }}
    >
      <Close
        style={{
          position: "fixed",
          color: "#5a5a5a",
          top: "20px",
          left: "26px",
          width: "18px",
          cursor: "pointer",
          zIndex: 333,
        }}
        className="close-button"
        onClick={() => closeFilterMenu()}
      />

      <Row className={"sticky-navbar"}>
        {/* PROJECT NAMES LIST */}
        <div
          id="top-black-box"
          style={{
            width: "calc(100% - 417px)",
            height: "20px",
            position: "absolute",
            background: "black",
            zIndex: "10",
            display: "none",
            left: 417,
          }}
        />
        <div>
          <Left
            id="project-buttons-container"
            className="p-filter-remove-black-line blank-bottom-space fix-scrollbar"
          >
            {/* <div id="filter-container-line" /> */}
            <div
              id="filter-container"
              style={{
                paddingBottom: mobileScreens ? "20vh" : "92vh",
                marginTop: "20px",
              }}
            >
              <div
                className="animated-border-container"
                style={{
                  height: `${animatedBorderContainerHeight}`,
                }}
              />
              {renderButtons()}
            </div>
          </Left>
          <Close
            className="close-button-mobile"
            onClick={() => closeFilterMenu()}
          />
          <div className="space-bottom"></div>
        </div>
        <Right>
          <Fixed>
            {isTitle === false ? (
              <>
                <Image
                  className={
                    img !== ""
                      ? "bw preview imgEffect"
                      : "bw preview imgEffect2"
                  }
                  src={img}
                />
              </>
            ) : (
              <>
                <MainTitle
                  style={{
                    maxHeight: "75%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    transition: "opacity .25s ease-in-out",
                  }}
                  className="title"
                >
                  {title}
                </MainTitle>
              </>
            )}
          </Fixed>
        </Right>
      </Row>
    </Container>
  );
}

export default FilterMenu;
